<template>
  <div id="app" ref="appRef">
    <div class="container-fluid pcg-page-container" :class="{ 'my-pcg-header': !signedIn && isLoginPath, 'my-page-container': !signedIn && isLoginPath }">
      <layout-header :logo="require('../assets/images/pcg_logo.svg')"
                        :negative-logo="require('../assets/images/pcg_logo.svg')"
                        :logoContainerClasses="sidebarMenuClasses"
                        class="my-layout-header">
        <template v-slot:upper>
          <span class="polon-version">{{ polonDisplayVersion() }}</span>
          <pcg-header-fonts class="icon-padding" :decrease-font="$t('general.decrease_font')" :enlarge-font="$t('general.enlarge_font')" v-on:click.native="calculateHeader"/>
          <pcg-header-contrast class="icon-padding" :switch-contrast="$t('general.change_contrast')"/>
          <pcg-language-selector class="pcg-lang" v-if="changeLanguage" :supportedLanguages="supportedLanguages"/>
        </template>
        <template v-slot:bottom>
          <pcg-header-navbar tabindex="-1" id="firstFocus" ref="headerNavbar" :class="{ 'pcg-12': fontSize === 12, 'pcg-14': fontSize === 14, 'pcg-16': fontSize === 16, 'pcg-18': fontSize === 18, 'pcg-20': fontSize === 20, }">
            <pcg-btn v-if="isLoginPath && !isWrongTenant" size="small" class="login-button ml-auto" @click="userLogin">{{ $t('components.navigation_menu.log_in') }}</pcg-btn>
            <pcg-btn v-if="isLoginPath && isWrongTenant" size="small" class="login-button ml-auto" @click="userWrongLogout">{{ $t('components.navigation_menu.logout') }} {{ wrongEmail }}</pcg-btn>
            <div class="text-center" v-if="!isLoginPath">
              <pcg-header-user-name class="username" :user-name="fullName" v-if="signedIn"/>
              <pcg-header-profile-button :currentRole="currentRole"
                                         :roles="roles"
                                         :supportedLanguages="supportedLanguages"
                                         @logout="userLogout"
                                         v-if="signedIn"
                                         @changeRole="changeRole"
                                         :avatar="getAvatar()"
              >
              </pcg-header-profile-button>
            </div>
          </pcg-header-navbar>
        </template>
      </layout-header>
      <b-row class="pcg-body-container">
        <nav v-if="!isLoginPath && !noSubscriptionPath" :aria-label="$t('general.main_menu')">
          <pcg-navigation-menu v-if="!isLoginPath" :current-role="currentRole"
                               :signed-in="signedIn"
                               :links="links">
          </pcg-navigation-menu>
        </nav>
        <div id="content" class="col main-content-margin" :class="{ 'small-margin': !menuOpen }">
          <main>
            <div class="row">
              <pcg-menu-switcher v-if="!isLoginPath && !noSubscriptionPath && signedIn"/>
              <div class="pcg-content-container" v-if="checkForNetworkError" tabindex="-1" id="firstContent">
                <transition name="slide" mode="out-in">
                  <router-view/>
                </transition>
              </div>
            </div>
          </main>
          <footer>
            <div class="row align-items-end">
              <layout-footer :logo='require("../assets/images/pcg_logo.svg")'></layout-footer>
            </div>
          </footer>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import LayoutHeader from 'pcg-core-vue/src/components/LayoutHeader'
import LayoutFooter from 'pcg-core-vue/src/components/LayoutFooter'
import PcgHeaderProfileButton from 'pcg-core-vue/src/components/PcgHeaderProfileButton'
import PcgHeaderUserName from 'pcg-core-vue/src/components/PcgHeaderUserName'
import PcgHeaderNavbar from 'pcg-core-vue/src/components/PcgHeaderNavbar'
import PcgLanguageSelector from 'pcg-core-vue/src/components/PcgLanguageSelector'
import PcgHeaderContrast from 'pcg-core-vue/src/components/PcgHeaderContrast'
import PcgHeaderFonts from 'pcg-core-vue/src/components/PcgHeaderFonts'
import PcgMenuSwitcher from 'pcg-core-vue/src/components/PcgMenuSwitcher'
import WindowResize from '../mixins/window_resize'
import { mapGetters } from 'vuex'
import supportedLanguages from '../translations/supported_langs'
import ApiAuth from '../api/auth'
import ApiUsers from '../api/users'
import InstitutionsApi from '../api/institutions'
import VueCookies from 'vue-cookies'
import Vue from 'vue'
import ApiInstitutions from '../api/institutions'
Vue.use(VueCookies)

export default {
  components: {
    PcgHeaderProfileButton,
    PcgHeaderUserName,
    PcgHeaderNavbar,
    PcgLanguageSelector,
    PcgHeaderContrast,
    PcgHeaderFonts,
    LayoutHeader,
    LayoutFooter,
    PcgMenuSwitcher
  },
  mixins: [WindowResize],
  data: function () {
    return {
      supportedLanguages: supportedLanguages,
      beforeRoute: null,
      fontSize: null,
      localLogin: false,
      links: [],
      originalTitle: 0,
      titleWidth: 0,
      checkUserInterval: null,
      subDomain: null,
      checkWrongTenantInterval: null,
      isWrongTenant: false,
      changeLanguage: null,
      wrongEmail: null,
      endSessionEndpoint: null,
      institutionLocked: false,
      installationVersion: 0,
      polonVersion: 0
    }
  },
  computed: {
    ...mapGetters('menu', ['menuOpen']),
    ...mapGetters('auth', ['signingOut', 'signedIn', 'roles', 'currentRole', 'fullName', 'expire']),
    ...mapGetters('sidebarMenu', ['sidebarMenuClasses']),
    ...mapGetters('page', ['contrast']),
    checkForNetworkError () {
      return !(!this.signedIn && !this.isLoginPath && this.$route.name !== 'dev_sessions_path' && this.$route.name !== 'no_rights_path' && this.$route.name !== 'no_subscription_path');
    },
    isLoginPath () {
      return ['login_path', 'root_path'].includes(this.$route.name)
    },
    noSubscriptionPath () {
      return ['no_subscription_path'].includes(this.$route.name)
    }
  },
  created () {
    this.getCookie()
    InstitutionsApi.getInstitutionInformation()
        .then(response => {
          this.endSessionEndpoint = response.data.endSessionEndpoint
          this.institutionLocked = response.data.locked
          this.installationVersion = response.data.installationVersion
          const locationUid = new URLSearchParams(window.location.search).get('uid');
          if (this.$route.query.uid || locationUid) {
            let uid = this.$route.query.uid
            if (uid === undefined){
              uid = locationUid
            }
            this.$store.dispatch('auth/redirSignIn', { id: uid })
                .then(response => {
                  const redirectTo = this.$store.getters['auth/getRedirectTo']
                  if (Vue.$cookies.get('redirect_url')) {
                    let url = window.location.origin
                    url += Vue.$cookies.get('redirect_url')
                    Vue.$cookies.remove('redirect_url')
                    setTimeout(() => {
                      window.location = url
                    }, 2000)
                  }
                  if (this.institutionLocked) {
                    this.$router.push({ name: 'no_subscription_path' }).catch(() => {})
                  } else if ((!Array.isArray(this.$store.getters['auth/roles']) || !this.$store.getters['auth/roles'].length)) {
                    clearInterval(this.checkUserInterval)
                    this.$router.push({ name: 'no_rights_path' }).catch(() => {})
                  } else if (redirectTo && redirectTo.name) {
                    this.$store.dispatch('auth/clearRedirectTo')
                    this.$router.push(redirectTo)
                  } else {
                    this.$router.push({ name: 'root_path' }).catch(error => {
                      console.log(error.message)
                    })
                  }
                })
                .catch(error => {
                  console.log(error.message)
                  this.$toastr.e(error.message)
                })
          }
          if (process.env.LOCAL_LOGIN == '1') {
            this.localLogin = true
          }
          // eslint-disable-next-line eqeqeq
          if (this.signedIn && this.checkUserInterval === null) {
            this.checkUserInterval = setInterval(this.checkUser, 60000)
          }
          if (!this.signedIn && this.checkWrongTenantInterval === null) {
            this.checkWrongTenantInterval = setInterval(this.getCookie, 2000)
          }
          if (this.signedIn && this.expire) {
            this.checkUser()
          }
          this.$store.dispatch('header/setSiteTitle', 'Integrator Polon')
          this.$store.dispatch('header/clearHeader')
          const s = document.querySelector('#app-loader').dataset.subDomain
          this.subDomain = s.length > 0 ? s : null
          this.$store.dispatch('page/setSubDomain', this.subDomain)
          if (this.signedIn && this.subDomain && this.subDomain !== 'global') {
            ApiInstitutions.getPolonCredentials()
                .then(result => {
                  this.polonVersion = result.data.configuration
                })
          }
        })
  },
  mounted () {
    this.$store.dispatch('locale/setInitialLocale', this.supportedLanguages)
    const html = document.documentElement
    if (this.$i18n.locale === 'pl') {
      html.setAttribute('lang', 'pl')
    } else {
      html.setAttribute('lang', 'en')
    }
    this.$root.$on('menuToggle', (toggle) => {
      this.menuOpen = toggle
    })
    this.fontSize = 14
    document.body.classList.forEach(cl => {
      if (cl === 'pcg-size-12' || cl === 'pcg-size-14' || cl === 'pcg-size-16' || cl === 'pcg-size-18' || cl === 'pcg-size-20') {
        this.fontSize = Number(cl.match(/\d/g).join(''))
      }
    })
    this.buildLinks()
    setTimeout(() => {
      document.getElementById('app-loader').style.display = 'none'
      if (this.windowWidth < 768 && this.isLoginPath) {
        document.getElementsByClassName('pcg-menu-btn')[0].style.display = 'none'
      }
      this.isLoading = false
      this.beforeRoute = this.$route.name
      this.calculateHeader()
    }, 2000)
  },
  watch: {
    windowWidth (newValue) {
      this.calculateSubtitleWidth()
      if (this.windowWidth < 768 && this.isLoginPath) {
        document.getElementsByClassName('pcg-menu-btn')[0].style.display = 'none'
      } else {
        document.getElementsByClassName('pcg-menu-btn')[0].style.display = 'visible'
      }
    },
    '$route.fullPath': {
      deep: true,
      handler () {
        if (this.beforeRoute === this.$route.name) {
          setTimeout(() => {
            this.calculateHeader()
          }, 1500)
        } else {
          setTimeout(() => {
            this.originalTitle = 0
            this.calculateHeader()
          }, 1500)
        }
        this.beforeRoute = this.$route.name
      }
    },
    '$route.path': {
      deep: true,
      handler () {
        if (this.beforeRoute !== this.$route.name) {
          setTimeout(() => {
            document.getElementById('firstFocus').focus()
          }, 1500)
        }
      }
    },
    signedIn (newValue) {
      if (newValue) {
        this.fetchUser()
        if (this.checkUserInterval === null) {
          this.checkUserInterval = setInterval(this.checkUser, 60000)
        }
      } else if (!newValue && !this.signingOut) {
        this.userLogout()
      }
    },
    '$i18n.locale': function (newLocale, oldLocale) {
      if (oldLocale !== newLocale) {
        this.buildLinks()
        const html = document.documentElement
        html.setAttribute('lang', newLocale)
        let titleLength = 0
        setTimeout(() => {
          if (this.originalTitle !== 0) {
            this.$refs.headerNavbar.$forceUpdate()
            titleLength = (this.$refs.headerNavbar.$children[0].$el.children[0].childNodes[0].length - 10)
            this.originalTitle = titleLength * 23
          }
          this.calculateHeader()
        }, 100)
      }
    },
    menuOpen: {
      deep: true,
      handler () {
        if (!this.menuOpen) {
          setTimeout(() => {
            this.calculateSubtitleWidth()
          }, 400)
        } else {
          this.calculateSubtitleWidth()
        }
      }
    },
    subDomain: {
      deep: true,
      handler () {
        this.buildLinks()
      }
    }
  },
  methods: {
    polonDisplayVersion () {
      if (this.subDomain !== 'global') {
        if (this.polonVersion.toString() === '0') {
          return 'POL-on DEMO'
        } else {
          return 'POL-on PRODUKCJA'
        }
      }
    },
    getCookie () {
      if (Vue.$cookies.get('wrong_login')) {
        this.isWrongTenant = true
        this.wrongEmail = Vue.$cookies.get('wrong_login')
      }
      return this.isWrongTenant
    },
    calculateHeader () {
      let font = null
      document.body.classList.forEach(cl => {
        if (cl === 'pcg-size-12' || cl === 'pcg-size-14' || cl === 'pcg-size-16' || cl === 'pcg-size-18' || cl === 'pcg-size-20') {
          const clInt = Number(cl.match(/\d/g).join(''))
          if (this.fontSize === 12) {
            if (clInt === 14) {
              this.originalTitle = this.originalTitle * 1.18
            }
          }
          if (this.fontSize === 14) {
            if (clInt === 12) {
              this.originalTitle = this.originalTitle * 0.865
            }
            if (clInt === 16) {
              this.originalTitle = this.originalTitle * 1.15
            }
          }
          if (this.fontSize === 16) {
            if (clInt === 14) {
              this.originalTitle = this.originalTitle * 0.88
            }
            if (clInt === 18) {
              this.originalTitle = this.originalTitle * 1.135
            }
          }
          if (this.fontSize === 18) {
            if (clInt === 16) {
              this.originalTitle = this.originalTitle * 0.9
            }
            if (clInt === 20) {
              this.originalTitle = this.originalTitle * 1.12
            }
          }
          if (this.fontSize === 20) {
            if (clInt === 18) {
              this.originalTitle = this.originalTitle * 0.91
            }
          }
          font = clInt
        }
      })
      if (font) {
        this.fontSize = font
      }
      this.calculateTitleWidth()
      this.calculateSubtitleWidth()
    },
    calculateTitleWidth () {
      if (!this.$refs.headerNavbar.$children[0].$el.children) { return }
      if (this.signedIn) {
        if (this.menuOpen) {
          this.$refs.headerNavbar.$el.style.width = (this.$refs.headerNavbar.$root.$el.childNodes[0].clientWidth - 270) + 'px'
        } else {
          this.$refs.headerNavbar.$el.style.width = (this.$refs.headerNavbar.$root.$el.childNodes[0].clientWidth - 100) + 'px'
        }
        if (this.originalTitle === 0) {
          this.originalTitle = this.$refs.headerNavbar.$children[0].$el.children[0].clientWidth
        }
        this.titleWidth = this.$refs.headerNavbar.$children[0].$el.children[0].clientWidth
      } else {
        this.originalTitle = this.$refs.headerNavbar.$children[0].$el.children[0].clientWidth
        this.titleWidth = this.$refs.headerNavbar.$children[0].$el.children[0].clientWidth
      }
    },
    calculateSubtitleWidth () {
      if (!this.$refs.headerNavbar.$children[0].$el.children) { return }
      const appWidth = this.$refs.headerNavbar.$root.$el.clientWidth
      let loginWidth = 0
      let navbarWidth = 0
      if (this.signedIn) {
        if (this.menuOpen) {
          this.$refs.headerNavbar.$el.style.width = (this.$refs.headerNavbar.$root.$el.childNodes[0].clientWidth - 270) + 'px'
        } else {
          this.$refs.headerNavbar.$el.style.width = (this.$refs.headerNavbar.$root.$el.childNodes[0].clientWidth - 100) + 'px'
        }
        if (appWidth < 753) {
          this.$refs.headerNavbar.$el.style.width = this.$refs.headerNavbar.$root.$el.childNodes[0].clientWidth + 'px'
        }
      }
      if (appWidth === 753) {
        this.$refs.headerNavbar.$children[0].$el.children[0].style.width = 280 + 'px'
        if (this.$refs.headerNavbar.$children.length > 2) {
          loginWidth = this.$refs.headerNavbar.$children[1].$el.offsetWidth + this.$refs.headerNavbar.$children[2].$el.offsetWidth + 20
        } else {
          loginWidth = this.$refs.headerNavbar.$children[1].$el.offsetWidth + 20
        }
        navbarWidth = this.$refs.headerNavbar.$el.clientWidth - 120
      } else {
        if (this.$refs.headerNavbar.$children.length > 2) {
          loginWidth = this.$refs.headerNavbar.$children[1].$el.offsetWidth + this.$refs.headerNavbar.$children[2].$el.offsetWidth + 20
        } else {
          loginWidth = this.$refs.headerNavbar.$children[1].$el.offsetWidth + 20
        }
        navbarWidth = this.$refs.headerNavbar.$el.clientWidth - 120
        if (loginWidth < 90) {
          navbarWidth = this.$refs.headerNavbar.$el.clientWidth - 100
        }
        this.$refs.headerNavbar.$children[0].$el.children[0].style.width = navbarWidth - (loginWidth + 10) + 'px'
        this.titleWidth = this.$refs.headerNavbar.$children[0].$el.children[0].clientWidth
        const subtitleWidth = navbarWidth - (loginWidth + this.originalTitle + 20)
        if (subtitleWidth > 150) {
          this.$refs.headerNavbar.$children[0].$el.children[0].firstElementChild.style.width = subtitleWidth + 'px'
        } else {
          this.$refs.headerNavbar.$children[0].$el.children[0].firstElementChild.style.width = 0
        }
        if (navbarWidth < 235 && loginWidth < 90) {
          this.$refs.headerNavbar.$children[0].$el.children[0].style.width = 0
        }
      }
    },
    getAvatar () {
      if (this.contrast) {
        return require('../assets/images/avatar-placeholder.png')
      } else {
        return require('../assets/images/avatar-placeholder.png')
      }
    },
    checkUser () {
      this.$store.dispatch('auth/checkUser')
          .then(response => {
            if (response) {
              if (this.expire) {
                clearInterval(this.checkUserInterval)
                this.userExpired()
              }
            } else {
              clearInterval(this.checkUserInterval)
              if (this.signedIn && !this.signingOut) {
                this.userLogout()
              }
            }
          })
          .catch(error => {
            console.log(error)
            clearInterval(this.checkUserInterval)
            if (this.signedIn && !this.signingOut) {
              this.userLogout()
            }
          })
    },
    fetchUser () {
      if (!this.signedIn) {
        return
      }
      this.$store.dispatch('auth/fetchUser')
          .then(response => {
            if ((!Array.isArray(this.$store.getters['auth/roles']) || !this.$store.getters['auth/roles'].length)) {
              this.$router.push({ name: 'no_rights_path' })
            }
            this.buildLinks()
          })
          .catch(error => {
            console.log(error.message)
            this.$toastr.e(error.message)
          })
    },
    updateStorage () {
      const fn = `${localStorage.getItem('ipFN')} ${localStorage.getItem('ipLN')}`
      if (fn !== this.fullName) {
        this.$store.dispatch('auth/updateState')
        this.fetchUser()
        this.router.go(0)
      }
    },
    userExpired () {
      this.$store.dispatch('auth/signOut')
          .then(response => {
            this.$store.dispatch('auth/setSigninOut', false)
            // eslint-disable-next-line eqeqeq
            if (process.env.LOCAL_LOGIN == '1') {
              window.location = '/login'
            } else {
              window.location = '/'
            }
          })
          .catch(error => {
            if (error.message === 'Network Error') {
              window.location = '/login'
            }
            console.log(error.message)
            this.$toastr.e(error.message)
          })
    },
    userLogout () {
      let fullLogout = false
      if (this.signedIn && !this.expire) {
        fullLogout = true
        clearInterval(this.checkUserInterval)
      }
      this.$store.dispatch('auth/signOut')
          .then(response => {
            this.$store.dispatch('auth/setSigninOut', false)
            this.$nextTick(() => {
              // eslint-disable-next-line eqeqeq
              if (process.env.LOCAL_LOGIN == '1') {
                window.location = '/login'
              } else {
                if (fullLogout) {
                  if (this.$store.getters['page/subDomain'] === 'global') {
                    window.location = `${this.endSessionEndpoint}?post_logout_redirect_uri=https://www.${process.env.SERVER_ADDRESS}`
                  } else {
                    window.location = `${this.endSessionEndpoint}?post_logout_redirect_uri=https://${this.$store.getters['page/subDomain']}.${process.env.SERVER_ADDRESS}`
                  }
                } else {
                  window.location = '/'
                }
              }
            })
          })
          .catch(error => {
            if (error.message === 'Network Error') {
              window.location = '/login'
            }
            console.log(error.message)
            this.$toastr.e(error.message)
          })
    },
    userWrongLogout () {
      this.$store.dispatch('auth/signOut')
          .then(response => {
            Vue.$cookies.remove('wrong_login')
            setTimeout(() => {
              if (this.$store.getters['page/subDomain'] === 'global') {
                window.location = `${this.endSessionEndpoint}?post_logout_redirect_uri=https://www.${process.env.SERVER_ADDRESS}`
              } else {
                window.location = `${this.endSessionEndpoint}?post_logout_redirect_uri=https://${this.$store.getters['page/subDomain']}.${process.env.SERVER_ADDRESS}`
              }
            }, 2000)
          })
          .catch(error => {
            console.log(error.message)
            this.$toastr.e(error.message)
          })
    },
    userLogin () {
      // eslint-disable-next-line eqeqeq
      // if (process.env.LOCAL_LOGIN == '1') {
      //   window.location = '/dev_login'
      // } else {
      //   window.location = `https://${this.subDomain}.${process.env.SERVER_ADDRESS}/users/auth/openid_connect`
      // }
      if (this.$store.getters['page/subDomain'] === 'global') {
        window.location = `https://www.${process.env.SERVER_ADDRESS}/users/auth/openid_connect`
      } else {
        window.location = `https://${this.subDomain}.${process.env.SERVER_ADDRESS}/users/auth/openid_connect`
      }
    },
    changeRole (role) {
      ApiAuth.setCurrentRole(role)
          .then(response => {
            this.$store.dispatch('auth/setCurrentRole', role)
            this.$nextTick(() => {
              // this.$router.go()
              window.location = '/'
            })
          })
          .catch(error => {
            console.log(error)
          })
    },
    buildLinks () {
      this.links = [
        {
          id: 1,
          name: this.$t('components.navigation_menu.institutions'),
          path: 'institutions_list_path',
          iconClass: 'fa fa-university',
          roles: ['systemadministrator', 'systemmanager'],
          hide: this.subDomain !== 'global',
          sub: []
        },
        {
          id: 2,
          name: this.$t('components.navigation_menu.import_initialization'),
          path: 'import_initialization_path',
          iconClass: 'fas fa-bezier-curve',
          hide: this.subDomain === 'global',
          roles: ['unitadministrator', 'employee'],
          sub: []
        },
        {
          id: 3,
          name: this.$t('components.navigation_menu.imports'),
          path: 'sis_imports_list_path',
          iconClass: 'fas fa-archive',
          hide: this.subDomain === 'global',
          roles: ['unitadministrator', 'employee'],
          sub: []
        },
        {
          id: 4,
          name: this.$t('components.navigation_menu.exports'),
          path: 'imports_list_path',
          iconClass: 'fas fa-archive',
          hide: this.subDomain === 'global',
          roles: ['unitadministrator', 'employee'],
          sub: []
        },
        {
          id: 5,
          name: this.$t('components.navigation_menu.users'),
          path: 'users_list_path',
          iconClass: 'fa fa-users',
          roles: ['systemadministrator', 'unitadministrator'],
          sub: []
        },
        {
          id: 6,
          name: this.$t('components.navigation_menu.integrations'),
          path: 'integrations_path',
          iconClass: 'fas fa-tasks',
          hide: this.subDomain === 'global',
          roles: ['unitadministrator'],
          sub: [
            {
              id: 61,
              name: this.$t('components.navigation_menu.token'),
              path: 'token_path',
              iconClass: 'fas fa-tasks',
              hide: this.subDomain === 'global',
              roles: ['unitadministrator'],
            },
            {
              id: 62,
              name: this.$t('components.navigation_menu.polon'),
              path: 'polon_path',
              iconClass: 'fas fa-tasks',
              hide: this.subDomain === 'global',
              roles: ['unitadministrator'],
            },
            {
              id: 63,
              name: this.$t('components.navigation_menu.sis_credentials'),
              path: 'sis_credentials_path',
              iconClass: 'fas fa-tasks',
              hide: this.subDomain === 'global',
              roles: ['unitadministrator'],
            }
          ]
        },
        {
          id: 7,
          name: this.$t('components.navigation_menu.students'),
          path: 'students_list_path',
          iconClass: 'fas fa-user',
          hide: this.subDomain === 'global' || this.installationVersion !== 1,
          roles: ['unitadministrator', 'employee'],
          sub: []
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/custom';
.pcg-btn-sidebar-menu {
  height: 46px;
  width: 25px;
  align-items: center;
}
.header-title {
  margin-bottom: 20px;
  display: block;
  padding-top: 20px;
}
.icon-padding {
  color: $main-active-color;
  padding-right: 10px;
}
.username {
  margin-right: 18px;
}
@media screen and (min-width: 1051px) {
  .header-title {
    display: none;
  }
}
.my-pcg-header {
  ::v-deep .pcg-header-container {
    .pcg-logo-container {
      display: none !important;
    }
  }
}
::v-deep .pcg-logo-container img {
  width: 100% !important;
  height: auto !important;
}
.my-page-container {
  ::v-deep .main-content-margin {
    padding-left: 0 !important;
  }
}
::v-deep .pcg-field {
  padding-top: 0;
  padding-bottom: 0;
  height: 2.14rem;
}
::v-deep .pcg-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
::v-deep .title-light {
  width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  height: 33px;
  vertical-align: middle;
}
.pcg-12 {
  ::v-deep .title-light {
    position: relative;
    bottom: -.3rem;
  }
}
.pcg-14 {
  ::v-deep .title-light {
    position: relative;
    bottom: -.1rem;
  }
}
.pcg-16 {
  ::v-deep .title-light {
    position: relative;
    bottom: .1rem;
  }
}

.pcg-18 {
  ::v-deep .title-light {
    position: relative;
    bottom: .2rem;
  }
}

.pcg-20 {
  ::v-deep .title-light {
    position: relative;
    bottom: .3rem;
  }
}

@media screen and (max-width: 1051px) {
  ::v-deep .header-title {
    display: inline-block;
  }
}
@media screen and (max-width: 767px) {
  ::v-deep .header-title {
    margin-left: 1rem;
  }
}
@media screen and (max-width: 300px) {
  ::v-deep .header-title {
    margin-left: 0;
  }
}
.skip-link {
  opacity: 0;
  margin-right: 1rem;
  color: $main-active-color;
  &:focus {
    opacity: 1;
  }
  a {
    color: unset;
    text-decoration: none !important;
    &:hover {
      color: unset;
      text-decoration: none !important;
    }
  }
}

.confirm-header {
  font-size: $font-size-m;
  font-weight: 400;
  color: $pcg-gray;
  margin-bottom: 1.5rem;
}

.polon-version {
  margin-right: 2rem;
  font-size: $font-size-m;
  font-weight: 500;
  color: $pcg-dark-gray;
}
</style>
<style lang="scss" scoped>
@import '../assets/stylesheets/custom-contrast';
.contrast {
  .icon-padding {
    color: $main-active-color;
  }
  .my-layout-header {
    ::v-deep .header-background {
      background: transparent !important;
    }
  }
  .skip-link {
    color: $main-active-color;
  }
  .confirm-header {
    color: $pcg-gray;
  }
  .polon-version {
    color: $pcg-dark-gray;
  }
}
</style>
