<template>
  <div>
    <ipo-loader v-if="showLoader" class="with-margins"/>
    <pcg-box v-else style="margin-top: 2rem;">
      <div v-if="structureLoader" style="min-height: 460px;">
        <ipo-loader class="with-margins"></ipo-loader>
      </div>
      <div v-else style="min-height: 500px;">
        <b-row class="d-flex justify-content-between" style="margin-bottom: 2rem;">
          <b-col cols="3">
            <label for="albumnumber" class="filter-album">Numer albumu</label>
            <div>
              <input
                  id="albumnumber"
                  type="text"
                  class="album-number"
                  placeholder="Numer albumu"
                  @keydown="addAlbumNumber"
                  v-model="inputText"
              />
            </div>
            <div v-if="filters.albumNumbers.length > 0" class="album-number-items">
              Wybrane:
              <div style="display: inline-block" v-for="(item, index) in filters.albumNumbers" :key="index">
                <div class="album-number-item" @click="removeAlbumNumber(index)">{{ item }} x</div>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <span class="album-number-communicat">W celu zapisania numeru albumu (można wpisać wiele numerów naraz po przecinku) należy po jego wpisaniu zatwierdzić go przyciskiem Enter</span>
          </b-col>
          <b-col cols="3" class="d-flex justify-content-end">
            <pcg-btn
                variant="additional"
                :disabled="generateImportSpinner"
                @click="initializeImport"
            >
              <span class="font-weight-bold">
                Importuj
              </span>
              <spinner v-if="generateImportSpinner"></spinner>
            </pcg-btn>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-between" style="margin-bottom: 2rem;">
          <b-col cols="12">
            <label for="import-name" class="filter-album">Nazwa importu</label>
            <div>
              <input
                  id="import-name"
                  type="text"
                  class="album-number"
                  placeholder="Nazwa importu"
                  v-model="filters.importName"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-center import-filters">
            Filtry importu
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 1rem;">
          <b-col class="col-12 col-sm-6 col-lg-3">
            <pcg-multi-select v-model="filters.faculties" :options="facultyOptions" @input="handleFilterChange('faculties')" track-by="name" select-label="name" label="Wydział"/>
          </b-col>
          <b-col class="col-12 col-sm-6 col-lg-3">
            <pcg-multi-select v-model="filters.courses" :options="courseOptions" @input="handleFilterChange('courses')" track-by="name" select-label="name" label="Kierunek"/>
          </b-col>
          <b-col class="col-12 col-sm-6 col-lg-3">
            <pcg-multi-select v-model="filters.levels" :options="levelOptions" @input="handleFilterChange('levels')" track-by="name" select-label="name" label="Poziom"/>
          </b-col>
          <b-col class="col-12 col-sm-6 col-lg-3">
            <pcg-multi-select v-model="filters.forms" :options="formOptions" @input="handleFilterChange('forms')" track-by="name" select-label="name" label="Forma"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 col-sm-6 col-lg-3">
            <ipo-select
                label="Semestr od"
                color="aqua"
                :box-shadow="true"
                v-model="filters.semesterFrom"
                :options="semesterFromOptions"
                :default-option="{ text: 'Wybierz', value: null }"
            />
          </b-col>
          <b-col class="col-12 col-sm-6 col-lg-3">
            <ipo-select
                label="Semestr do"
                color="aqua"
                :box-shadow="true"
                v-model="filters.semesterTo"
                :options="semesterToOptions"
                :default-option="{ text: 'Wybierz', value: null }"
            />
          </b-col>
          <b-col class="col-12 col-sm-6 col-lg-3">
            <pcg-datetime-picker
                class="my-datetimepicker"
                :locale="$i18n.locale"
                label="Import na dzień"
                variant="date"
                v-model="filters.statusDate"
            />
          </b-col>
        </b-row>
      </div>
    </pcg-box>
  </div>
</template>

<script>
import PcgMultiSelect from '../../components/PcgMultiSelect.vue'
import IpoSelect from '../../components/IpoSelect.vue'
import SisImportApi from '../../api/sis_imports'
import loaderDelay from '../../mixins/loader_delay'
import IpoLoader from '../../components/IpoLoader.vue'
import Spinner from '../../components/Spinner.vue'
export default {
  name: 'ImportInitializationView',
  components: {Spinner, IpoLoader, PcgMultiSelect, IpoSelect },
  mixins: [loaderDelay],
  data: function () {
    return {
      filters: {
        faculties: [],
        courses: [],
        levels: [],
        forms: [],
        semesterFrom: null,
        semesterTo: null,
        albumNumbers: [],
        statusDate: new Date(),
        importName: null
      },
      semesterFromOptions: [],
      semesterToOptions: [],
      facultyOptions: [],
      courseOptions: [],
      levelOptions: [],
      formOptions: [],
      inputText: '',
      structureLoader: false,
      generateImportSpinner: false
    }
  },
  created () {
    this.initializeStructure()
    this.initializeSemesters()
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: 'Inicjalizacja importu',
      subtitle: '',
      capitalizeSubtitle: false
    })
  },
  watch: {
    'filters.albumNumbers': {
      deep: true,
      handler () {
        this.filters.faculties = []
        this.filters.courses = []
        this.filters.levels = []
        this.filters.forms = []
      }
    }
  },
  methods: {
    initializeImport () {
      if (this.inputText && this.filters.albumNumbers.length === 0) {
        this.$toastr.i('Nie wprowadzono numeru albumu. Aby zapisać numer albumu należy po wpisaniu go w polu zatwierdzić przyciskiem Enter.')
      } else if (!this.filters.importName) {
        this.$toastr.i('Nie wprowadzono nazwy importu.')
      } else {
        this.generateImportSpinner = true
        SisImportApi.initializeImport(this.filters)
            .then(result => {
              setTimeout(() => {
                this.$toastr.i('Generowanie pakietu rozpoczęte')
                this.$router.push({ name: 'sis_imports_list_path' })
              }, 2500)
            })
            .catch(error => {
              this.generateImportSpinner = false
              console.log(error)
              this.$toastr.e('Wystąpił błąd podczas generowania pakietu')
            })
      }
    },
    addAlbumNumber (event) {
      if (event.key === ' ' || event.key === 'Enter') {
        if (this.inputText.trim() !== '') {
          this.inputText.split(',').forEach(text => {
            if (text.trim() !== '') {
              this.filters.albumNumbers.unshift(text.trim());
            }
          })
          this.inputText = '';
        }
        event.preventDefault()
      }
    },
    removeAlbumNumber (index) {
      this.filters.albumNumbers.splice(index, 1);
    },
    handleFilterChange(filterName) {
      if (filterName === 'faculties') {
        this.filters.courses = []
        this.filters.levels = []
        this.filters.forms = []
      } else if (filterName === 'courses') {
        this.filters.levels = []
        this.filters.forms = []
      } else if (filterName === 'levels') {
        this.filters.forms = []
      }
      this.initializeStructure()
    },
    initializeStructure () {
      this.structureLoader = true
      this.facultyOptions = []
      this.courseOptions = []
      this.levelOptions = []
      this.formOptions = []
      SisImportApi.initializeStructure(this.filters)
          .then(result => {
            result.data.structure.faculty.forEach(faculty => {
              this.facultyOptions.push({ name: faculty, value: faculty })
            })
            result.data.structure.course.forEach(course => {
              this.courseOptions.push({ name: course, value: course })
            })
            result.data.structure.level.forEach(level => {
              this.levelOptions.push({ name: level, value: level })
            })
            result.data.structure.form.forEach(form => {
              this.formOptions.push({ name: form, value: form })
            })
            this.structureLoader = false
          })
          .catch(error => {
            console.log(error)
            this.filters.faculties = []
            this.filters.courses = []
            this.filters.levels = []
            this.filters.forms = []
            this.$toastr.e('Nie udało pobrać się struktury')
            this.structureLoader = false
          })
          .finally(() => {
            this.loadingDone = true
          })
    },
    initializeSemesters() {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth() + 1
      const currentDay = currentDate.getDate()
      const dateBeforeOctober = new Date(currentYear, 9, 1) // 1 października
      dateBeforeOctober.setDate(dateBeforeOctober.getDate() - 14) // 14 dni przed 1 października
      const dateBeforeMarch = new Date(currentYear, 2, 1) // 1 marca
      dateBeforeMarch.setDate(dateBeforeMarch.getDate() - 14) // 14 dni przed 1 marca
      const isNearOctober = currentDate >= dateBeforeOctober && currentDate < new Date(currentYear, 9, 1)
      const isNearMarch = currentDate >= dateBeforeMarch && currentDate < new Date(currentYear, 2, 1)

      let options = []
      if ((currentMonth >= 10 && currentMonth <= 12) || (currentMonth >= 1 && currentMonth < 3)) {
        if (currentMonth >= 10 && currentMonth <= 12) {
          options = [
            { text: `${currentYear.toString()}/${(currentYear + 1).toString()} zimowy`, value: currentYear.toString() + ' zimowy' },
            { text: `${(currentYear.toString() - 1)}/${currentYear.toString()} letni`, value: (currentYear.toString() - 1) + ' letni' }
          ]
        } else {
          options = [
            { text: `${(currentYear.toString() - 1)}/${currentYear.toString()} zimowy`, value: (currentYear.toString() - 1) + ' zimowy' },
            { text: `${(currentYear.toString() - 2)}/${(currentYear.toString() - 1)} letni`, value: (currentYear.toString() - 2) + ' letni' }
          ]
          if (isNearMarch) {
            options.unshift({ text: `${(currentYear.toString() - 1)}/${currentYear.toString()} letni`, value: (currentYear.toString() - 1) + ' letni' })
          }
        }
      } else {
        options = [
          { text: `${(currentYear.toString() - 1)}/${currentYear.toString()} letni`, value: (currentYear.toString() - 1) + ' letni' },
          { text: `${(currentYear.toString() - 1)}/${currentYear.toString()} zimowy`, value: (currentYear.toString() - 1) + ' zimowy' },
        ]
        if (isNearOctober) {
          options.unshift({ text: `${currentYear.toString()}/${(currentYear + 1).toString()} zimowy`, value: currentYear.toString() + ' zimowy' })
        }
      }
      this.semesterFromOptions = options
      this.semesterToOptions = options
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/stylesheets/vars';
::v-deep .pcg-field {
  height: 2.86rem !important;
}

.album-number {
  width: 100%;
  margin-right: 1rem;
}

.album-number-items {
  color: $pcg-gray;
  margin-top: .6rem;
  font-weight: 400;
}

.album-number-item {
  padding: 0 6px;
  background: $pcg-lightest-gray-background;
  border-radius: 25px;
  cursor: pointer;
  margin-right: .4rem;
  margin-bottom: .4rem;
  display: inline-block;
}

.my-datetimepicker {
  ::v-deep .pcg-datetimepicker-input {
    font-weight: 400;
    color: $pcg-dark-gray;
    height: 30px;
  }
  ::v-deep .pcg-datetimepicker-btn {
    height: auto;
    position: relative;
    bottom: 2px;
  }
}

.filter-album {
  color: $pcg-the-darkest-gray;
  font-weight: 500;
  font-size: $font-size-s;
  line-height: 1rem;
  margin-bottom: .71rem;
}

.album-number-communicat {
  color: $pcg-the-darkest-gray;
  font-weight: 500;
  font-size: $font-size-s;
  position: relative;
  top: 2rem;
}

.import-filters {
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: $font-size-xl;
  color: $main-color;
}
</style>

<style lang="scss">
@import "../../assets/stylesheets/vars-contrast";
.contrast {
  .album-number-items {
    color: $pcg-gray;
    margin-top: .6rem;
    font-weight: 400;
  }

  .album-number-item {
    padding: 0 6px;
    background: $pcg-lightest-gray;
    border-radius: 25px;
    cursor: pointer;
    margin-right: .4rem;
    margin-bottom: .4rem;
    display: inline-block;
  }

  .my-datetimepicker {
    ::v-deep .pcg-datetimepicker-input {
      color: $pcg-dark-gray;
    }
  }

  .filter-album {
    color: $pcg-the-darkest-gray;
  }

  .album-number-communicat {
    color: $pcg-the-darkest-gray;
  }

  .import-filters {
    color: $main-color;
  }
}
</style>